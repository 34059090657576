import React from 'react';
import './MeetTheTeam.css';

function MeetTheTeam() {
  return (
    <div className="meet-the-team-page">

      {/* Hero Section */}
      <section className="team-hero">
        <h1>Meet the Team</h1>
        <h2>Yale Net Impact Executive Board Fall 2024</h2>
      </section>

      {/* Team Grid Section */}
      <section className="team-grid">
        <div className="team-member">
          <img src="headshots/joy_headshot.jpg" alt="Team Member" />
          <h3>Joy Qu</h3>
          <p>President</p>
          <p>Joy is a junior studying Statistics & Data Science and Economics in Berkeley College. She comes from Hoffman Estates, IL and loves to play squash in her free time. Joy joined YNI in fall of 2022, and has worked with clients in various industries including healthcare and consumer products. One of her favorite memories in YNI is organizing initiation and meeting the new members every semester. </p>
        </div>

        <div className="team-member">
          <img src="headshots/beckett_headshot.jpg" alt="Team Member" />
          <h3>Beckett Elkins</h3>
          <p>President</p>
          <p>Beckett is a junior studying Global Affairs and Philosophy in Pauli Murray College. Before coming to Yale, he grew up in Gambier, Ohio and worked as a part-time line cook in high school. Beckett joined YNI  as a freshman, and has worked with clients ranging from a Nigerian sexual awareness non-profit to a global consumer brand. One of his favorite activities is meeting with prospective members for coffee chats at the beginning of each semester.</p>
        </div>
        
        <div className="team-member">
          <img src="headshots/daniel_headshot.jpg" alt="Team Member" />
          <h3>Daniel Zhang</h3>
          <p>Vice President</p>
          <p>Daniel Zhang is a junior in Timothy Dwight majoring in Statistics & Data Science. In his free time he enjoys exploring outdoors and talking about current events.</p>
        </div>

        <div className="team-member">
          <img src="headshots/eva_headshot.jpg" alt="Team Member" />
          <h3>Eva Kottou</h3>
          <p>Vice President</p>
          <p>Eva Kottou is a junior double-majoring in Economics and Sociology in Pauli Murray College. Eva grew up in Farmington, CT and has always had a passion for exploring both quantitative as well as writing-based qualitative projects. Eva joined YNI as a freshman in the fall of 2022 and has worked with both local and international clients across the healthcare, education, and law industries. </p>
        </div>

        <div className="team-member">
          <img src="headshots/dillon_headshot.jpg" alt="Team Member" />
          <h3>Dillon Kim</h3>
          <p>Vice President</p>
          <p>Dillon is a junior in Branford College studying Statistics & Data Science. He was born and raised in South Korea, but moved soon thereafter to Southern California, where he now calls home. His hobbies include fitness, cooking, and reading comic books. Dillon joined YNI in the spring of 2024, and was placed on a consumer product client project, and now leads a data-oriented endeavor. Dillon loves the YNI community, and especially values the various backgrounds that members hail from.</p>
        </div>

        <div className="team-member">
          <img src="headshots/catherine_headshot.jpg" alt="Team Member" />
          <h3>Catherine Lee</h3>
          <p>Social Media Chair</p>
          <p>Catharine is a sophomore in Morse College studying Cognitive Science and Comparative Literature. She joined YNI in the Fall of 2023 and her favorite memories include when she was initiated into the group and met all of the members of YNI. She is from New York, NY and enjoys playing golf in her free time.</p>
        </div>

        <div className="team-member">
          <img src="headshots/vishak_headshot.jpg" alt="Team Member" />
          <h3>Vishak Srikanth</h3>
          <p>Treasurer</p>
        </div>

        <div className="team-member">
          <img src="headshots/susannah_headshot.jpeg" alt="Team Member" />
          <h3>Susannah Brown</h3>
          <p>Head of Client Acquisition</p>
          <p>Susannah Brown is a junior majoring in Global Affairs with an Advanced Spanish certificate. She is from Montclair, NJ, but considers Saybrook College her home away from home. In her free time, she likes to take photographs and do the NYT crossword. Susannah joined YNI in the fall of 2023, and has since worked with clients in the following sectors: consumer products, government and public services, environmental, and healthcare. Her favorite YNI memories are announcing new client partnerships each semester and seeing everyone's excitement!</p>
        </div>

        <div className="team-member">
          <img src="headshots/avi_headshot.jpg" alt="Team Member" />
          <h3>Avi Kabra</h3>
          <p>Head of Technology</p>
          <p>Avi is a sophomore in Pierson College studying Applied Math. He was born in New Jersey, but moved soon thereafter to Singapore, which he now calls home. His hobbies include basketball and playing the guitar. Avi joined YNI in the fall of 2023, and was placed on a data analysis and growth development project.</p>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>© 2024 Yale Net Impact</p>
        <div className="social-icons">
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src="/instagram.png" alt="Instagram" />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src="/linkedin.webp" alt="LinkedIn" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default MeetTheTeam;
