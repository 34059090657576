import React from 'react';
import './JoinUs.css';

function JoinUs() {
  return (
    <div className="join-us-page">

      {/* Hero Section */}
      <section className="join-us-hero">
        <h1>Join Us!</h1>
        <p>Reach out and we’ll get back to you with how to get involved.</p>
      </section>

      {/* Form Section */}
      <section className="join-us-form">
        <form action="mailto:joy.qu@yale.edu" method="POST" enctype="text/plain">
          <div className="form-group">
            <label htmlFor="first-name">Name</label>
            <div className="name-inputs">
              <input type="text" id="first-name" placeholder=" First Name" />
              <input type="text" id="last-name" placeholder=" Last Name" />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder=" Yale Email" />
          </div>
          <div className="form-group">
            <label htmlFor="about">Tell Us About Yourself!</label>
            <textarea id="about" placeholder=" I am..."></textarea>
          </div>

          <button type="submit">Submit</button>
        </form>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>© 2024 Yale Net Impact</p>
        <div className="social-icons">
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src="/instagram.png" alt="Instagram" />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src="/linkedin.webp" alt="LinkedIn" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default JoinUs;
