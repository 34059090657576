import React from 'react';
import './OurServices.css';

function OurServices() {
  return (
    <div className="our-services-page">

      {/* Hero Section */}
      <section className="services-hero">
        <h1>Our Services</h1>
      </section>

      {/* New Image Section */}
        <section className="image-section">
        <div className="image-container">
            <img src="/DSCF5122.jpg" alt="group"></img>
            <img src="/DSCF5183.jpg" alt="single"></img>
        </div>
        </section>

      {/* What YNI Offers */}
      <section className="yni-offers">
        <h2>What YNI Offers</h2>
        <p>Professionalism, Expertise, Knowledge</p>
        <p>
          We tackle high-impact, multifaceted projects that require collaboration and dedication. Our undergraduate consultants are trained in cutting-edge industry methods, allowing them to approach client problems with confidence. As a student-run organization, we work like a professional consulting firm while ensuring client satisfaction through high-impact solutions.
        </p>
      </section>

      {/* Services Breakdown (Research, Marketing, Operations, etc.) */}
      <section className="services-breakdown">
        <div className="service-item">
          <div className="service-icon">🔍</div>
          <h3>Research</h3>
          <ul>
            <li>Market Research</li>
            <li>Demographic Analysis</li>
            <li>Investment Analysis</li>
            <li>Localization Analysis</li>
          </ul>
        </div>

        <div className="service-item">
          <div className="service-icon">📈</div>
          <h3>Strategy</h3>
          <ul>
            <li>Lifecycle Analysis</li>
            <li>Market Entry Strategy</li>
            <li>Benchmarking</li>
          </ul>
        </div>

        <div className="service-item">
          <div className="service-icon">💬</div>
          <h3>Marketing</h3>
          <ul>
            <li>Social Media Strategy</li>
            <li>Campaign Development</li>
            <li>PR Strategy</li>
          </ul>
        </div>

        <div className="service-item">
          <div className="service-icon">⚙️</div>
          <h3>Operations</h3>
          <ul>
            <li>Sustainability Analysis</li>
            <li>Impact Evaluation</li>
            <li>Process Mapping</li>
            <li>Structural Analysis</li>
          </ul>
        </div>

        <div className="service-item">
          <div className="service-icon">📊</div>
          <h3>Growth</h3>
          <ul>
            <li>Timeline Projection</li>
            <li>Time Series and Forecasting</li>
            <li>Revenue Models</li>
          </ul>
        </div>
      </section>

        {/* Footer */}
      <footer className="footer">
        <p>© 2024 Yale Net Impact</p>
        <div className="social-icons">
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src="/instagram.png" alt="Instagram" />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src="/linkedin.webp" alt="LinkedIn" />
          </a>
        </div>
      </footer>

    </div>
  );
}

export default OurServices;
