import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <img id="logo" src="/yni_logo.png" alt="Yale Net Impact Logo" ></img>
      </div>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/services">Our Services</Link></li>
          <li><Link to="/team">Meet the Team</Link></li>
          <li><Link to="/join">Join Us</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
