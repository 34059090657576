import React from 'react';
import Slider from 'react-slick'; // Import the slider component
import './HomePage.css';

function HomePage() {
    const brandImages = [
        'carousel/brand_1.png',
        'carousel/brand_2.jpg',
        'carousel/brand_3.png',
        'carousel/brand_4.png',
        'carousel/brand_5.webp',
        'carousel/brand_6.jpg',
        'carousel/brand_7.png',
        'carousel/brand_8.webp',
        'carousel/brand_9.png',
        'carousel/brand_10.jpg',
      ];
    
      // Slider settings
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Number of logos visible at once
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000, // Change time between transitions (in milliseconds)
        pauseOnHover: true
      };

  return (
    

    <div className="home-page">
      
      {/* Hero Section */}
      <section className="hero">
        <div class="content">
            <h1>Yale Net Impact</h1>
            <p>Premier Consulting for Socially Minded Business</p>
            <div className="buttons">
            <button>Learn More</button>
            <button>Join Us</button>
            </div>
        </div>
      </section>

      {/* What We Do Section */}
      <section className="what-we-do">
        <h2>Who We Are</h2>
        <div className="section-content">
          <p><strong>Socially-Minded Consultants:</strong> Our founders created YNI with the mission of creating Yale student-led consulting for socially-minded enterprises.</p>
          <p><strong>International Organization:</strong> With over 2000 members and 30 active universities, Yale Net Impact focuses on bridging the gap between the business world and the social sector.</p>
          <p><strong>Bridging Social and Business:</strong> We work on engagement projects with nonprofits and join entrepreneurial and socially-minded special initiatives each year from semester to semester.</p>
        </div>
      </section>

      {/* Who We Are Section */}
      <section className="who-we-are">
        <h2>What We Do</h2>
        <div className="section-content">
          <p><strong>Consulting Projects:</strong> Our consulting projects are composed of multi-month efforts designed to provide members, project leaders, and a senior advisor with the thorough research needed to deliver results to our clients.</p>
          <p><strong>Special Initiatives:</strong> Our special initiatives are opportunities to develop Yale's social entrepreneurship space, to work with socially-minded groups on original socially-minded ventures.</p>
          <p><strong>Pre-Professional Training:</strong> Past trainings have included Entrepreneurial Leadership, the ELI Pitch Competition, the International Business course, and more.</p>
        </div>
      </section>

      {/* Brand Carousel Section */}
      <section className="brand-carousel">
        <h2>Our Partners</h2>
        <Slider {...settings}>
          {brandImages.map((brand, index) => (
            <div key={index}>
              <img src={brand} alt={`Brand ${index + 1}`} className="brand-logo" />
            </div>
          ))}
        </Slider>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>© 2024 Yale Net Impact</p>
        <div className="social-icons">
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src="/instagram.png" alt="Instagram" />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src="/linkedin.png" alt="LinkedIn" />
          </a>
        </div>
      </footer>
      
    </div>
  );
}

export default HomePage;
